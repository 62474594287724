import localFont from "@next/font/local";
import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import Script from "next/script";
import { useEffect, useRef } from "react";
import {
	SharedLoadingIndicatorContextProvider,
	SharedProgressLoadingIndicator,
} from "shared-loading-indicator";
import { PageNavigationLoadingTracker } from "../components/PageNavigationLoadingTracker";
import "../styles/globals.sass";
import { api } from "../utilities/api";

export const ClashGrotesk = localFont({
	src: [
		{
			path: "../styles/fonts/ClashGrotesk-Regular.woff2",
			weight: "400",
			style: "normal",
		},
		{
			path: "../styles/fonts/ClashGrotesk-Medium.woff2",
			weight: "500",
			style: "normal",
		},
		{
			path: "../styles/fonts/ClashGrotesk-SemiBold.woff2",
			weight: "600",
			style: "normal",
		},
		{
			path: "../styles/fonts/ClashGrotesk-Bold.woff2",
			weight: "700",
			style: "normal",
		},
	],
	display: "swap",
});

function MyApp({ Component, pageProps }: AppProps) {
	const router = useRouter();

	const isFirstPageView = useRef(true);

	useEffect(() => {
		const fbPixelId = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID;
		if (fbPixelId) {
			import("react-facebook-pixel")
				.then((x) => x.default)
				.then((ReactPixel) => {
					ReactPixel.init(fbPixelId);

					const pageView = () => {
						ReactPixel.pageView();
					};
					if (isFirstPageView.current === true) {
						isFirstPageView.current = false;
						pageView();
					}

					router.events.on("routeChangeComplete", pageView);
					return () => {
						router.events.off("routeChangeComplete", pageView);
					};
				});
		}
	}, [router.events]);

	return (
		<>
			<Script id="pixel" strategy="lazyOnload">
				{`!function(f,b,e,v,n,t,s)
				{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
				n.callMethod.apply(n,arguments):n.queue.push(arguments)};
				if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
				n.queue=[];t=b.createElement(e);t.async=!0;
				t.src=v;s=b.getElementsByTagName(e)[0];
				s.parentNode.insertBefore(t,s)}(window, document,'script',
				'https://connect.facebook.net/en_US/fbevents.js');
				fbq('init', '1798927480587417');
				fbq('track', 'PageView');
				`}
			</Script>
			<SharedLoadingIndicatorContextProvider>
				<SharedProgressLoadingIndicator />
				<PageNavigationLoadingTracker />
				<style jsx global>{`
					:root {
						--font-clash-grotesk: ${ClashGrotesk.style.fontFamily};
						--font-body: var(--font-clash-grotesk);
						--font-heading: var(--font-body);
					}
					html {
						font-family: ${ClashGrotesk.style.fontFamily};
					}
				`}</style>
				<Component {...pageProps} />
			</SharedLoadingIndicatorContextProvider>
		</>
	);
}

export default api.withTRPC(MyApp);
